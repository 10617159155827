<script>
import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  name: 'CommentsCounter',
  components: { Icon },
  props: {
    commentsNumber: {
      type: Number,
      required: false,
      default: 0,
    },
  },
});
</script>

<template>
  <div class="relative">
    <Icon :name="commentsNumber === 0 ? 'commentsDisabled' : 'comments'" />
    <div
      v-if="commentsNumber !== 0"
      class="commentNumberPlacement absolute z-10 flex h-4.5 w-4.5 items-center justify-center rounded-full bg-error text-xs text-white"
    >
      {{ commentsNumber }}
    </div>
  </div>
</template>

<style scoped>
.commentNumberPlacement {
  right: -5px;
  top: -4px;
}
</style>
